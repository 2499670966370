<script setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useGtm } from '@gtm-support/vue-gtm';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Checkmark from '@/Components/Input/Checkmark.vue';
import { createInput } from '@formkit/vue';

const checkmark = createInput(Checkmark);

const route = inject('route');
const props = defineProps({
    tag: String,
    age: String,
});

const emits = defineEmits(['submissionSuccessful']);

const gtm = useGtm();

const form = useForm({
    nickname: '',
    email: '',
    // postcode: '',
    // phone_number: '',
    age: '',
    tag: '',
    affiliate_code: '',
    terms_and_conditions: false,
});

const sendForm = () => {
    form.transform((data) => ({
        ...data,
        affiliate_code: getAffiliateCode(),
        age: props.age,
        tag: props.tag,
    })).post(route('form.signup'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
        onSuccess: (page) => {
            if (typeof fbq !== 'undefined') {
                fbq('track', 'form-submission', { content_category: props.tag });
            }

            if (typeof gtm !== 'undefined') {
                gtm.trackEvent({
                    event: 'employee_lead',
                    landingspage: props.tag,
                });
            }

            fbq('track', 'form-submission', { content_category: props.tag });

            gtm.trackEvent({
                event: 'employee_lead',
                landingspage: props.tag,
            });

            gtm.trackEvent({
                event: 'employee_lead_registration',
                landingspage: props.tag,
            });

            emits('submissionSuccessful');
        },
    });
};

const getAffiliateCode = () => {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
        return urlParams.get('code');
    }
    return '';
};
</script>

<template>
    <FormKit type="form" id="signup-bonus" @submit="sendForm" :actions="false">
        <div class="grid gap-4">
            <FormKit
                type="text"
                name="name"
                :placeholder="$t('Name')"
                v-model="form.nickname"
                :errors="form.errors.nickname"
                :value="form.nickname"
                validate="required"
            />
            <FormKit
                type="text"
                name="email"
                :placeholder="$t('Email')"
                v-model="form.email"
                :errors="form.errors.email"
                :value="form.email"
                validate="required"
            />

            <!-- <div class="grid grid-cols-2 gap-4">
                <FormKit
                    type="text"
                    name="postcode"
                    :placeholder="$t('Postal code')"
                    v-model="form.postcode"
                    :errors="form.errors.postcode"
                    :value="form.postcode"
                    validate="required"
                />
                <FormKit
                    type="text"
                    name="phone"
                    :placeholder="$t('Phone number')"
                    v-model="form.phone_number"
                    :errors="form.errors.phone_number"
                    :value="form.phone_number"
                    validate="required"
                />
            </div> -->

            <Checkmark v-model:value="form.terms_and_conditions" :error="form.errors.terms_and_conditions">
                <div class="leading-tight">
                    {{ $t('I accept the') }}
                    <a target="_blank" class="font-bold underline" :href="route('terms')"> {{ $t('general terms') }}</a>
                    {{ $t('and') }}
                    <a target="_blank" class="font-bold underline" :href="route('privacy')">
                        {{ $t('privacy statement') }}</a
                    >
                </div>
            </Checkmark>

            <Button
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('I sign up!')"
            />
        </div>
    </FormKit>
</template>
